<script lang="ts" setup>
import { ImgHTMLAttributes } from 'vue'
import { MediaImageFragment } from '~/api/graphql/generated'

type Size = [number | null, string][]

const imageSizes = {
  teaser: [
    [768, '100vw'],
    [1024, '50vw'],
    [null, '520px'],
  ] as Size,
  hero: [
    [1800, '100vw'],
    [null, '1800px'],
  ] as Size,
}

type ImageSizes = keyof typeof imageSizes

interface Slice {
  width: number
  height: number
  url: string
}

const getSizes = (type: ImageSizes) =>
  imageSizes[type]
    .reduce((acc, item) => {
      if (!item[0]) return `${acc}${item[1]}, `
      return `${acc}(max-width: ${item[0] - 1}px) ${item[1]}, `
    }, '')
    .slice(0, -2)

const getSrcset = (data: Slice[]) =>
  data.reduce((acc: string, slice: Slice): string => `${acc}${slice.url} ${slice.width}w,`, '').slice(0, -1)

const props = withDefaults(
  defineProps<{
    small?: MediaImageFragment
    large?: MediaImageFragment
    type: ImageSizes
    loading?: 'lazy' | 'eager'
  }>(),
  {
    type: 'teaser',
    small: undefined,
    large: undefined,
    loading: 'lazy',
  },
)

type ImageData = MediaImageFragment['fieldMediaImage']

const getAttrs = (imageData: ImageData) => {
  const original = {
    width: imageData?.width || 0,
    height: imageData?.height || 0,
    url: imageData?.url || '',
  }

  const keys = ['small', 'smallRetina', 'medium', 'mediumRetina', 'large', 'largeRetina', 'xlarge', 'xxlarge'] as const
  const data = [
    ...keys.map((key) => imageData?.[key] as Slice).filter((slice) => slice?.width !== original.width),
    3000 > original.width ? original : undefined,
  ].filter(Boolean)

  return {
    width: original.width,
    height: original.height,
    src: data[0].url || '',
    srcset: getSrcset(data),
    sizes: getSizes(props.type),
    alt: imageData?.alt || '',
    loading: props.loading,
  } as ImgHTMLAttributes
}

const image = {
  small: getAttrs(props.small?.fieldMediaImage),
  large: getAttrs(props.large?.fieldMediaImage),
}
</script>
<template>
  <picture v-if="image.small.src || image.large.src" class="dp-picture">
    <source
      v-if="image.large.src"
      :width="image.large.width"
      :height="image.large.height"
      :srcset="image.large.srcset"
      :sizes="image.large.sizes"
      media="(min-width: 768px)"
    />
    <img v-bind="image.small.src ? image.small : image.large" />
  </picture>
</template>

<style scoped lang="scss">
img {
  object-fit: cover;
}
</style>
